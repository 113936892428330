<template>
  <b-card no-body>
    <b-card-header>Rules</b-card-header>
    <b-card-body>
      <div align="justify">
        <h3>Terms</h3>
        <ul>
          <li>
            <strong>Commissioner:</strong> Mickey
          </li>
          <li>
            <strong>Offseaon:</strong> The time of year where it is frowned upon to say football is back
          </li>
        </ul>
        <h3>Rules</h3>
        <p>Each week up to five games will be placed into the app. The five matchups will feature the following teams or events: Iowa State, Iowa, Nebraska, ESPN Gameday, and the Game of the week. These games will be put into the app each Thursday prior to the matchup. This season will use the Action Network for all the sports betting odds. Each player will be required to bet on a winner, the line (point spread), and total (over/under) prior to the kickoff of each match.</p>
        <p>Anyone can participate in the game, however, the winner is required to be a Iowa State season ticket holder. If the individual in first is not a season ticket holder, the trophy go to the next player who is.</p>
        <p>The game-winner will be decided the week prior to the final Iowa State home game (Week 12).  There will be a shitty award ceremony at the final home game where the trophy will be handed over to the douchebag that wins. The game-winner is responsible for returning the traveling trophy to the Commissioner at the beginning of the following season. The game may continue after Nov. 30th but no trophy can be won. We will consider this time as "Bowl Season". We are not raising a bunch of pussies here so no participation ribbons will be awarded if the leaderboard changes during the Bowl Season.</p>
        <p>Similarly, the game-loser (person in last place) will be presented with the Poop Glove. The previous year's Poop Glove recipient will be responsible for handoff. This will most likely be an awkward experience for all of us as we watch Brennan present the poop glove to himself.</p>
        <p>Like last year, true American odds will be tracked for all bets. This will allow you to see the monetary value you would earn if money was placed on a game. All earnings are calculated based off a $1 wager for each the money line, spread, and total. These will be displayed on the scoreboard and in the future may become the points system allowing for a risk/reward system.</p>
        <p>In the event of a tie for first place the winner will be decided by who has the most money in their bank of winnings. Similarly, in the event of a tie for last place the loser will be decided by who has the least money in their bank.</p>
        <p>By placing bets within this app you agree to the Commissioners terms and conditions. The Commissioner has issued the following terms:</p>
        <ul>
          <li>The Commissioner reserves the right to "ICE" one or more player per home game. The player that gets "ICE'd" is not allowed to refuse this request. If a player does choose to refuse this request the Commissioner holds the right to deduct points or disqualify the player.</li>
          <li>If you own an Andriod phone or name starts with the letter 'B' you are automatically disqualified from winning.</li>
          <li>If any player complains about game rules, terms, and/or conditions the Commissioner retains the right to request a corndog from the concessions from said player. If that player refuses the Commissioners request for a corndog the Commissioner retains the right to disqualify the player at any time during the season.</li>
        </ul>

        <p>Rules cannot be changed during the regular season. If players wish to make changes to the rules they must speak to the Commissioner during the Offseason. New rules will be discussed and amendments will be made for the following seasons.</p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "Rules"
};
</script>